.App {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  padding: 0; /* Remove default padding from the list */
  margin: 0; /* Remove default margin from the list */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    padding: 0; /* Remove default padding from the list */
    margin: 0; /* Remove default margin from the list */
  }
}

.App-header {
  background-color: #C9F1F8;
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #C9F1F8;
  padding: 0; /* Remove default padding from the list */
  margin: 0; /* Remove default margin from the list */
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
  padding: 0; /* Remove default padding from the list */
  margin: 0; /* Remove default margin from the list */
}

.App-link {
  color: #C9F1F8;
  padding: 0; /* Remove default padding from the list */
  margin: 0; /* Remove default margin from the list */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  min-height: 100%;
  min-width: 100%;
  background-color: #C9F1F8; /* Change to your desired color */
  padding: 0; /* Remove default padding from the list */
  margin: 0; /* Remove default margin from the list */
}

.centered {
  justify-content: center;  /*Horizontally centers the content */
  /*align-items: center;  Vertically centers the content */
  /*height: 100vh;  Full viewport height */
}

.list {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove default padding from the list */
  margin: 0; /* Remove default margin from the list */
}

.list-item {
  display: block; /* Stack elements vertically */
  padding: 0; /* Remove default padding from the list */
  margin: 0; /* Remove default margin from the list */
  text-align: left; /* Align content to the left */
}

.list-image {
  display: inline-block; /* Align image and text inline */
  vertical-align: middle; /* Align image with text */
  padding: 0; /* Remove default padding from the list */
  margin: 0; /* Remove default margin from the list */
}

.list-text {
  display: inline-block; /* Align text inline with image */
  vertical-align: middle; /* Align text with the image */
  font-family: "Roboto", sans-serif;
  font-style: normal;
  padding: 0; /* Remove default padding from the list */
  margin: 0; /* Remove default margin from the list */
}